<template>
  <div>
    <div class="container">
      <div class="content-box">
        <h1>Guilds And Blades</h1>
        <p>Guilds & Blades is a multiplayer browser strategy game that is free to play. The game
          focuses on team play instead of individual players. Communication and cooperation among
          members of your guild is key to winning. Each game round lasts approximately one month.
          There are resource production cycles every hour. The game will be accessible on mobile and
          web browsers. The game is currently in development and is in the early access alpha that
          you can try now.
          <router-link :to="{ name: 'AboutTheGameArticleView'}">Read more.</router-link>
        </p>

        <h3>Sign up for Early Access Alpha</h3>
        <p>Be the first to join by signing up!</p>
        <Button id="play-button"
                url="https://game.guildsandblades.com/"
                title="Play"
                class="button-lg"/>

        <h3>Main game mechanics:</h3>
        <ul>
          <li>Hex grid maps with different types of terrain</li>
          <li>Flexible movement around the world</li>
          <li>4 main resources: gold, food, wood and iron</li>
          <li>Marketplace prices reacting to supply and demand of each resource</li>
          <li>Economy building</li>
          <li>Military recruitment</li>
          <li>Magic casting</li>
          <li>5 different races with different perks and cons: Humans, Elves, Dwarves, Orcs, The
            Undead
          </li>
          <li>Guilds - forming teams with other players and fighting other guilds for a domination
            and map control
          </li>
        </ul>

        <h3>Playable races</h3>
        <div class="mb1">You can choose out of 5 different races, each with its own pros
          and cons.
        </div>
        <VerticalEntry :entries="racesResult" v-if="racesResult"/>

        <template>
          <h3>Screenshots</h3>
          <p>Most recent screenshots. You can view more screenshots
            <router-link to="/media">here</router-link>
            .
          </p>
          <ImagesGrid :images="firstThreeImages"/>
        </template>

        <h3>Science</h3>
        <ImageWrapper class="mb1"
                      src="//images.ctfassets.net/mgtrjprkfcfc/5IpQ80XZRp7vgjOdRZp6wk/887f6907779c31748f6ae845a16ffebe/science-lab.d836b08f.png"
                      :desktop-width="640" :ar="[5,2]" alt="Science"/>
        <div>Each science level gives additional benefits to the player. And with each level other
          science prices will
          increase. So you'll have to decide where to focus depending on your gameplay style.
        </div>
        <ul>
          <li>Military - science increases attack and defence status for all soldiers. It is best
            suited for players who like to fight.
          </li>
          <li>Economy - increases food, wood and iron production.</li>
          <li>Magic - a powerful science that not only increases your magic power, but also unlocks
            different spells. Some spells may be essential for everyone, but if you're dedicated to
            being a mage, it's worth it to get to a higher level so you can support your guild
            members with better spell casting.
          </li>
        </ul>

        <h3>Magic</h3>
        <ImageWrapper class="mb1"
                      src="//images.ctfassets.net/mgtrjprkfcfc/GlWpq85CUqr1qLUndSSA9/ca9738412075bd93e8190bdd024ea2a3/wizard-casting-spell.070cf62b.png"
                      :desktop-width="640" :ar="[5,2]" alt="Wizard"/>

        <div>Spells have major role in a game. Casting each spell requires different magic level.
        </div>
        <ul>
          <li><span class="highlighted-text">Arcane eye</span> - lets to you see what troops an
            enemy has in his army or city.
          </li>
          <li><span class="highlighted-text">Berzek</span> - increases army morale just for one hour
            to fight until the last drop.
          </li>
          <li><span class="highlighted-text">Blizzard</span> - freezes an enemy army in place for
            some time in order to stop it moving.
          </li>
          <li><span class="highlighted-text">Mind Control</span> - forces enemy troops to fight for
            your side.
          </li>
          <li><span class="highlighted-text">Shielding Aura</span> - makes harder for enemy to cast
            spells on city or army.
          </li>
          <li><span class="highlighted-text">Magic Missiles</span> - shoots magic projectiles to
            enemy army killing some soldiers.
          </li>
          <li><span class="highlighted-text">Resurrection</span> - resurrects some allied troops
            that died in the battle previously.
          </li>
          <li><span class="highlighted-text">Cure Wounds</span> - heals injured troops.</li>
          <li><span class="highlighted-text">Earthquake</span> - destroys enemy buildings in a city.
          </li>
          <li><span class="highlighted-text">Flooding Storm</span> - makes an empty area unpassable
            for some time.
          </li>
          <li><span class="highlighted-text">Armageddon</span> - ends the game round for everybody.
          </li>
          <li><span class="highlighted-text">And many more ...</span></li>
        </ul>

        <h3>Market</h3>
        <ImageWrapper class="mb1"
                      src="//images.ctfassets.net/mgtrjprkfcfc/3f1LpfbMYx0AsTF6lpjLzO/3b4b97db7af3d0bad76d5a20c48a004a/market.56c9d8b4.png"
                      :desktop-width="640" :ar="[5,2]" alt="Market"/>
        <div>The in game market is a place where prices for resources are determined by supply and
          demand. Players do not buy directly from other players, but rather from the market itself.
          The market prices for resources are constantly changing, based on how much is being bought
          and sold. There are three main resources that can be traded on the market: food, wood, and
          iron.
        </div>

        <h3>Buildings</h3>
        <div class="mb1">
          You can build different type of buildings in your towns.
        </div>

        <HorizontalEntry :entries="buildingsResult" v-if="buildingsResult"/>
      </div>
    </div>
  </div>

</template>

<script>
import Button from '@/components/Button'
import ImagesGrid from '@/components/ImagesGrid'
import { mapActions, mapState } from 'vuex'
import VerticalEntry from '@/components/VerticalEntry'
import HorizontalEntry from '@/components/HorizontalEntry'
import ImageWrapper from '@/components/ImageWrapper'

export default {
  name: 'HomeView',
  components: {
    ImageWrapper,
    HorizontalEntry,
    VerticalEntry,
    ImagesGrid,
    Button
  },
  metaInfo () {
    return {
      title: 'Guilds & Blades - Free Multiplayer Strategy Game',
      meta: [{
        name: 'description',
        content: 'Guilds & Blades - browser based multiplayer strategy game. Form guilds, build cities, cast magic, fight and conquer other players on hex grid map! It\'s like a board game, but on your smart device and on much larger scale!'
      }]
    }
  },
  computed: {
    ...mapState({
      mediaResult: s => s.media.result,
      racesResult: s => s.races.result,
      buildingsResult: s => s.buildings.result
    }),
    firstThreeImages () {
      if (!this.mediaResult) {
        return []
      }

      return this.mediaResult.fields.images.slice(0, 3)
    }
  },
  async mounted () {
    await Promise.all([this.mediaFetch(), this.racesFetch(), this.buildingsFetch()])
  },
  methods: {
    ...mapActions({
      mediaFetch: 'media/fetchPage',
      racesFetch: 'races/fetchRaces',
      buildingsFetch: 'buildings/fetchBuildings'
    })
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

ul {
  //list-style: lao;

  li {
    padding: 0.1rem 0;
  }
}

h3 {
  margin-top: $spacing4;
}

.button-lg {
  @media(min-width: $breakpointDesktopLg) {
    max-width: 300px;
  }
}

.wide-image {
  width: 100%;
  margin-bottom: $spacing1;

  @media(min-width: $breakpointDesktopLg) {
    max-width: 640px;
  }
}

.mb1 {
  margin-bottom: $spacing1;
}
</style>
