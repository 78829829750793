<template>
  <div class="entry-container">
    <div v-for="entry in entries.items" :key="entry.sys.id" class="entry">
      <ImageWrapper class="entry__image"
                    :src="entry.fields.image.fields.file.url"
                    :desktop-width="100"
                    :max-width="100"
                    :alt="entry.fields.title"
                    :ar="[3, 2]"
      />

      <div class="entry__right-container">
        <div class="entry__title">
          {{ entry.fields.title }}
        </div>
        <div class="entry__description">
          {{ entry.fields.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageWrapper from '@/components/ImageWrapper'
export default {
  name: 'HorizontalEntry',
  components: { ImageWrapper },
  props: {
    entries: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/variables.scss';

.entry-container {
  display: flex;
  flex-direction: column;
  gap: $spacing1;

  .entry {
    display: flex;
    gap: $spacing1;

    @media(min-width: $breakpointDesktopLg) {
      flex-direction: row;
    }

    &__title {
      font-weight: bold;
    }

    &__image {
      max-width: 250px;
    }

    &__description {
      //font-size: smaller;
    }
  }
}
</style>
