<template>
  <div>
    <template v-if="routerLink">
      <router-link :id="id" :to="routerLink" class="button">{{ title }}</router-link>
    </template>
    <template v-else>
      <a :id="id" :href="url" class="button">{{ title }}</a>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    id: String,
    routerLink: String,
    url: String,
    title: String
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/variables.scss';

.button {
  display: block;

  background-color: $colorDarker;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  color: $colorLink;
  font-family: $fontFamilyHeadings;
  font-weight: bold;

  border: 1px solid $colorLink;
  border-radius: 50px;

  text-align: center;
  text-decoration: none;
  padding: $spacing1;

  &:hover {
    color: $colorLinkHover;
    border: 1px solid $colorLinkHover;
    transition-property: color, border;
    transition-duration: 0.2s;
  }

  &:active {
    color: $colorLinkActive;
    border: 1px solid $colorLinkActive;
    transition-property: color, border;
    transition-duration: 0.2s;
  }
}
</style>
