<template>
  <div class="entry-container">
    <div v-for="entry in entries.items" :key="entry.sys.id" class="entry">
      <div class="entry__title">
        {{ entry.fields.title }}
      </div>
      <ImageWrapper class="entry__image"
                    :src="entry.fields.image.fields.file.url"
                    :desktop-width="250"
                    :max-width="250"
                    :alt="entry.fields.title"
                    :ar="[2, 3]"
      />
      <div class="entry__description">
        {{ entry.fields.description }}
      </div>
    </div>
  </div>
</template>

<script>
import ImageWrapper from '@/components/ImageWrapper'

export default {
  name: 'VerticalEntry',
  components: { ImageWrapper },
  props: {
    entries: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/variables.scss';

.entry-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: $spacing1;

  .entry {
    max-width: 250px;

    &__title {
      font-weight: bold;
      font-weight: bolder;
      text-align: center;
      margin-bottom: 5px;
    }

    &__image {
      width: fit-content;
    }

    &__description {
      //font-size: smaller;
    }
  }
}
</style>
